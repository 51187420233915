<template>
	<div>
		<div id="editor" style="text-align:left"></div>
	</div>
</template>

<script>
	import WangEditor from "wangeditor"
	export default {
		name: "editor",
		model: {
			prop: "editorContent",
			event: "change"
		},
		props: {
			editorContent: {
				required: true
			}
		},
		data() {
			return {
				fun: null, //更换粘贴图片url的函数
				edi: null //富文本对象
			}
		},
		mounted() {
			var this_ = this;
			/*实例化*/
			var editor = new WangEditor("#editor");
			this.edi = editor
			/*设置上传图片*/
			editor.config.uploadImgServer = this.$url + '/upload/img'; //上传图片的请求地址
			editor.config.uploadFileName = 'file'; //上传图片的参数名
			editor.config.uploadImgMaxLength = 1; // 一次最多上传1张图片
			editor.config.uploadImgHeaders = { //自定义上传图片请求的头
				token: JSON.parse(localStorage.getItem('Token')),
			}
			editor.config.uploadImgHooks = { //上传图片的回调函数
				customInsert: function(insertImg, res) { //处理上传成功之后返回的数据
					var url = res.data.url; //服务器端的图片地址
					this_.$emit("img", url); //将地址传递到父组件中
					insertImg(url); //插入到富文本中
				}
			};
			// 配置粘贴文本的内容处理
			editor.config.pasteTextHandle = function(pasteStr) {
				var div = document.createElement('div'); //创建一个div
				div.innerHTML = pasteStr; //将文章内容添加到div中
				var a = div.getElementsByTagName('a'); //查找div中所有a标签
				a.forEach(item => { //循环查找到的a标签数组
					item.removeAttribute("href"); //去掉a标签的超链接
				})
				var img = div.getElementsByTagName('img'); //查找div中所有img标签
				if (img.length > 0) { //判断是否有网络图片，有的话替换地址，没有直接插入
					this_.fun = async function(length) {
						length--;
						let url = img[length].src;
						let file = await this_.$util.func.getBase64(url); //将网络图片的url转换成文件格式
						var forms = new FormData();
						var configs = {
							headers: {
								token: JSON.parse(localStorage.getItem('Token')) //请求token
							},
							transformRequest: [] //上传文件去掉请求格式化
						};
						forms.append('file', file);
						try {
							let res = await this_.$http.post(this_.$url + '/upload/img', forms,
							configs); //将图片文件上传到服务器
							img[length].src = res.data.data.url; //将粘贴过来的网络图片替换成自己服务器的图片地址
						} catch (err) {	//上传出错后提醒
							this_.$message.error('您粘贴内容的第' + (length+1) + '张图片地址修改失败，请手动修改');
							console.log('您粘贴内容的第' + (length+1) + '张图片地址修改失败，请手动修改');
						}
						if (length == 0) { //判断还有没有图片
							editor.cmd.do('insertHTML', div.innerHTML); //将修改后的粘贴文档插入到光标处
						} else {
							this_.fun(length); //有图片继续更换
						}
					}
					this_.fun(img.length); //执行更换url函数
				} else {
					editor.cmd.do('insertHTML', div.innerHTML); //将修改后的文档插入到光标处
				}
				return '';
			};
			// 文本内容改变，将数据传递到父组件
			editor.config.onchange = html => {
				this.$emit("change", html);
			};
			/*创建编辑器*/
			editor.create();
		},
		methods: {
			setHtml(data) {
				/*初始内容*/
				this.edi.txt.html(data);
			}
		},
		watch: {
			editorContent(newVal, oldVal) {
				this.editorContent = newVal;
			}
		}
	};
</script>
